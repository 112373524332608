import { ref } from "vue";
import dayjs from "dayjs";
import * as pointsApi from "../../api/points";

const columns = [
  {
    title: "日期",
    key: "time",
  },
  {
    title: "积分",
    key: "pointStr",
  },
  {
    title: "说明",
    key: "desc",
  },
];
export const useTable = () => {
  const currentPoints = ref(0);
  const usedPoints = ref(0);
  const pointList = ref([]);
  const totalPage = ref(0);
  const currentPage = ref(0);

  const getPointList = async (mfcId, page) => {
    try {
      const res = await pointsApi.pointsList({mfc_id: mfcId,page_size: 10,page:page});
      const len = res.data.list.length;
      const data = res.data.list;
      const list = [];
      for (var i = 0; i < len; i++) {
        const time = dayjs(data[i]["add_time"] * 1000).format("YYYY-MM-DD HH:mm");
        const desc = data[i]["points_desc"];
        let pointStr = "";
        if (data[i].points_type == 1) {
          //增加
          pointStr = "+" + data[i].points_num;
        } else {
          //减少
          pointStr = "-" + data[i].points_num;
        }

        list.push({ time, desc, pointStr });
      }
      pointList.value = list;
      usedPoints.value = res.data.used_points;
      currentPoints.value = res.data.current_points;
      totalPage.value = res.data.page.page_total;
      currentPage.value = res.data.page.page_index;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getPointList,
    columns,
    currentPoints,
    usedPoints,
    pointList,
    totalPage,
    currentPage,
  };
};
