<template>
  <div  class="jifen">
    <nut-cell
      :title="'面粉厂名字:' + mfcSelectName"
      @click="show = true"
      is-link
    />

    <div class="pointsShow">
      <span>当前积分：{{ currentPoints }} </span>
      <span> 已用积分：{{ usedPoints }}</span>
    </div>

    <div class="pointList">
      <nut-table :columns="columns" :data="pointList" bordered></nut-table>
    </div>
    <nut-picker v-model:visible="show" :columns="mfcList" title="面粉厂选择" @change="changeMfc" />
    <div style="text-align: center;margin-top: .3rem">
      <nut-pagination class="pageBottom"  v-show="totalPage" v-model="currentPage" :page-count="totalPage" mode="simple" @change="pageChange" />
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useOptionsConfig } from "../../hooks/optionsConfig";
import { useTable } from "./useTable.js";

export default {
  setup() {
    const show = ref(false);
    const mfcSelectName = ref("");

    const { mfcList, mfcSelected } = useOptionsConfig();

    const { getPointList, columns, currentPoints, usedPoints, pointList, totalPage, currentPage } = useTable();
    const changeMfc = ({ selectedValue }) => {
      mfcSelected.value = selectedValue[0];
    };
    const getMfcNameByMfcId = (mfcId) => {
      var mfcName = "";
      var mfcListLen = mfcList.value.length;
      for(var i=0;i<mfcListLen;i++){
        if(mfcList.value[i].value == mfcId){
          mfcName = mfcList.value[i].text;
        }
      }
      return mfcName;
    };

    watch(mfcSelected, (mfcId) => {
      getPointList(mfcId,1);
      mfcSelectName.value = getMfcNameByMfcId(mfcId);
    });

    const pageChange = (value) => {
      getPointList(mfcSelected.value,value)
    };
    return {
      getPointList,
      pageChange,
      changeMfc,
      show,
      mfcList,
      mfcSelected,
      mfcSelectName,
      pointList,
      currentPage,
      totalPage,
      columns,
      currentPoints,
      usedPoints,
    };
  },
};
</script>

<style scoped lang="scss">
.jifen {
  .mfcList {
    width: 60%;
    height: 2rem;
    border: 1px solid #a9a9a9;
  }
  .pointsShow {
    padding: 0.2rem 0.8rem;
    background-color: #ffffff;
    height: 2rem;
  }
  .pointList{
    margin-top: 1rem;
  }
  :deep(.nut-cell__title) {
    align-items: flex-start;
    font-size: 15px;
    font-weight: 500;
  }
  :deep(.nut-cell__value) {
    font-size: 15px;
    font-weight: 500;
    color: #666 !important;
  }
  .nut-pagination {
    margin-left:20px;
  }
}
</style>
